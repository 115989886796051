<template>
  <div
    class="app-admin-wrap layout-sidebar-compact   clearfix"
  >
    <div class="main-content-wrap d-flex flex-column">
      <transition name="page" mode="out-in">
        <router-view></router-view>
      </transition>
      <appFooter></appFooter>
    </div>
  </div>
</template>
<script>
import appFooter from "../common/footer";
export default {
  components: {
    appFooter
  },
  data() {
    return {};
  },
  computed: {
    
  }
};
</script>
